import React from 'react'
import Layout from "../components/layout";
import {Typography} from "@material-ui/core";

export default () => {
  return (
    <Layout>
      <div style={{backgroundColor: "#0E1118", padding: "10vh 15vw"}}>
        <Typography variant="h3">
          About
          <br/>
          <br/>
          We’re a company founded by computer scientists from all across the world. With extensive experience in GPGPU
          programming, our founding members spent years working on projects where deep architectural knowledge of GPU’s
          and flawless performance stood at the centre of development. However, we knew that things could be better. By
          combining our knowledge, we set out to create a company that didn’t just want to be good, we wanted to be the
          best.
          <br/>
          <br/>
          But we didn’t just want to be the best at what we do, we wanted to do it in a way that reflects our values.
          While we pride ourselves on the development of best-in-class libraries and unique solutions without making
          sacrifices, what we won’t sacrifice are our core values.
          <br/>
          <br/>
          Embracing challenges and out-of-the-box thinking, flexible and pleasant working conditions and a flat
          organization structure don’t only ensure that our employees can do what they do best, but also do it while
          they’re at their best – without sacrificing their mental or physical health, without prejudice and without
          nonsensical interference or harassment from anywhere or anyone.
        </Typography>
      </div>
    </Layout>
  )
}
